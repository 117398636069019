body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main, div.login{
  min-height: 95vh;
  min-height: calc(100vh - 48px - 56px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-link.active{
  position: relative;
}

.nav-link.active::after{
  content: "";
  display: block;
  width: 100%;
  height: 7px;
  background-color: #fff;
  position: absolute;
  bottom:-7px;
  left:0;
}

@media screen and (max-width: 767px) {
  .nav-link.active::after{
    width: 4px;
    height: 100%;
    left: -10px;
    bottom: 0;
  }
}

